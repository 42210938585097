/** @jsx jsx **/
import { jsx, Select as BaseSelect, Box, Label, SxStyleProp } from 'theme-ui'
import React, { FC, SelectHTMLAttributes } from 'react'
import { Maybe } from '~/@types/models'
import ErrorMessage from '~/components/error-message'

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string
  options: readonly string[]
  error?: Maybe<string>
  setValue?: any
  selectDefaultOption?: string
  selectDefaultOptionDisabled?: boolean
  labelUppercase?: boolean
  selectAsImput?: boolean
  sxLabel?: SxStyleProp
}

export const Select: FC<Props> = ({
  label,
  options,
  error,
  setValue,
  selectDefaultOption,
  selectDefaultOptionDisabled,
  labelUppercase,
  selectAsImput,
  sxLabel,
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        select: {
          appearance: 'none',
          pr: '25px',
        },
        svg: {
          position: 'absolute',
          right: '25px',
          pointerEvents: 'none',
          ...(selectAsImput && {
            position: 'relative',
            right: '0',
          }),
        },
      }}
    >
      {label && (
        <Label
          sx={{
            // fontSize: '16px ',
            lineHeight: '19px',
            // color: 'mutedLight',
            mb: '16px',
            ...sxLabel,
            ...(labelUppercase && {
              textTransform: 'uppercase',
            }),
            ...(selectAsImput && {
              fontSize: '16px !important',
              color: '#595858',
            }),
            // color: '#595858',
          }}
        >
          {label}
        </Label>
      )}
      <BaseSelect
        {...rest}
        {...(setValue && {
          onChange: e => setValue(e.target.value),
        })}
        width={10}
      >
        {options.map((item, index) => {
          if (index === 0 && selectDefaultOption) {
            return (
              <React.Fragment>
                <option selected disabled={!!selectDefaultOptionDisabled}>
                  {selectDefaultOption}
                </option>
                <option value={item} key={item}>
                  {item}
                </option>
              </React.Fragment>
            )
          }

          return (
            <option value={item} key={item}>
              {item}
            </option>
          )
        })}
      </BaseSelect>
      {error && <ErrorMessage error={error} />}
    </Box>
  )
}
