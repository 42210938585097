import React from 'react'
import { ThemeUICSSObject } from 'theme-ui'
import './styles.css'

type Level = '1' | '2' | '3' | '4' | '5' | '6'
interface HeadingProps {
  level?: Level
  children?: React.ReactNode
  style?: React.CSSProperties
  sx?: ThemeUICSSObject
  className?: string
}

const HeadingV2 = ({ children, ...props }: HeadingProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { level = '1', sx, ...rest } = props
  const CurrentTag = `h${level}` as keyof JSX.IntrinsicElements
  const className = `heading-component level-${level} ${rest?.className}`

  return (
    <CurrentTag {...rest} className={className}>
      {children}
    </CurrentTag>
  )
}

export default HeadingV2
export type { HeadingProps }
