/** @jsx jsx */
import { FC, useEffect } from 'react'
import { useFormikContext } from 'formik'

type Props = {}

const ErrorFocus: FC<Props> = () => {
  const formikContext = useFormikContext()
  const { isSubmitting, isValidating, errors } = formikContext

  useEffect(() => {
    const keys = Object.keys(errors)

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[id="${keys[0]}"]`
      const errorElement = document.querySelector<HTMLInputElement>(selector)

      if (errorElement) {
        errorElement.focus()
      }
    }
  }, [errors, isSubmitting, isValidating])

  return null
}

export default ErrorFocus
