/** @jsx jsx */
import { jsx, Container } from 'theme-ui'
import ForgotPasswordContainer from '~/containers/forgot-password'
import Layout from '~/components/layout'
import HeadingV2 from '~/componentsV2/heading'

const ForgoPassword = () => {
  return (
    <Layout footer={false} headerVariant="default">
      <Container
        variant="mid"
        sx={{
          pt: [51, null, 108],
        }}
      >
        <HeadingV2
          sx={{
            textAlign: 'center',
            mb: [44, null, 87],
          }}
        >
          Reset password
        </HeadingV2>
        <ForgotPasswordContainer />
      </Container>
    </Layout>
  )
}

export default ForgoPassword
