import styled, { css } from 'styled-components'

export const WrapperInput = styled.div`
  display: flex;
  position: relative;

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-4px, -50%);
  }
`
