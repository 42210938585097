/** @jsx jsx **/
import { jsx, Box, Text, Flex, Input, Label } from 'theme-ui'
import { FC, InputHTMLAttributes } from 'react'
import ErrorMessage from '~/components/error-message'
import { Maybe } from '~/@types/models'

type optionProps = {
  id: string
  label: string
}

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string
  options: optionProps[]
  error?: Maybe<string>
}

const RadioGroup: FC<Props> = ({ label, options, name, error, ...rest }) => {
  return (
    <Box>
      <Text
        variant="small"
        sx={{
          color: 'muted',
          mb: 10,
        }}
      >
        {label}
      </Text>
      <Flex
        sx={{
          flexWrap: 'wrap',
        }}
      >
        {options.map(option => (
          <Box
            key={option.id}
            sx={{
              position: 'relative',
              mr: '16px',
              mb: '16px',
            }}
          >
            <Input
              {...rest}
              value={option.label}
              name={name}
              id={option.id}
              checked={option.label === rest.value}
              type="radio"
              sx={{
                opacity: 0,
                position: 'absolute',
                width: '1px',
                height: '1px',
                overflow: 'hidden',
                ':focus': {
                  '+ label:before': {
                    borderColor: '#2b2b2b',
                  },
                },
                ':checked': {
                  '+ label:after': {
                    backgroundColor: '#2b2b2b',
                  },
                },
              }}
            />
            <Label
              htmlFor={option.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 0,
                ':before': {
                  content: '""',
                  display: 'block',
                  variant: 'variants.inputRadioCheckbox',
                  borderRadius: '50%',
                  mt: 0,
                  mr: '8px',
                },
                ':after': {
                  content: '""',
                  display: 'block',
                  variant: 'variants.inputRadioCheckboxInside',
                  borderRadius: '50%',
                  top: '2px',
                  left: '2px',
                },
              }}
            >
              {option.label}
            </Label>
          </Box>
        ))}
      </Flex>
      {error && <ErrorMessage error={error} />}
    </Box>
  )
}

export default RadioGroup
