/** @jsx jsx **/
import { jsx, Label, Checkbox as StyledCheckbox, Box, Flex } from 'theme-ui'
import React, { FC, ReactNode, useState } from 'react'
import { Maybe } from '~/@types/models'
import ErrorMessage from '~/components/error-message'

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  label?: string | ReactNode
  error?: Maybe<string>
  value: any
  small?: boolean
  labelSx?: any
}

export const Checkbox: FC<Props> = ({ label, error, small = false, labelSx, ...rest }) => {
  const [focused, setFocused] = useState<boolean>(false)

  return (
    <Flex
      as="div"
      sx={{
        alignItems: 'flex-start',
        mb: '18px',
      }}
    >
      <Label
        className="checkbox-label"
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          cursor: 'pointer',
          textTransform: 'uppercase',
          mb: 0,
          ...(small
            ? {
                fontSize: [1, 1, 1],
                lineHeight: 1,
              }
            : {
                fontSize: '16px important!',
                lineHeight: ['28px', null, '34px'],
              }),
        }}
      >
        <StyledCheckbox
          variant="variants.visuallyHidden"
          sx={{
            pt: 0,
          }}
          {...rest}
          defaultChecked={Boolean(rest.value)}
          onFocus={e => {
            if (e.currentTarget === e.target) {
              setFocused(true)
            }
          }}
          onBlur={e => {
            if (e.currentTarget === e.target) {
              setFocused(false)
            }
          }}
          type="checkbox"
        />
        <Box
          className="checkbox"
          as="span"
          sx={{
            variant: 'variants.inputRadioCheckbox',
            ':before': {
              content: '""',
              left: '1px',
              variant: 'variants.inputRadioCheckboxInside',
              bg: rest.value ? '#808080' : 'transparent',
            },
            ...(focused && {
              borderColor: 'text',
            }),
            mt: 0,
            borderColor: '#808080',
          }}
        />
        <Box
          as="span"
          className="checkbox-text"
          sx={{
            color: 'muted',
            fontSize: [0],
            a: {
              color: 'inherit',
            },
            ...(small && {
              pt: 10,
            }),
            fontWeight: Boolean(rest.value) ? 'bold' : 'normal',
            ...labelSx,
          }}
        >
          {label}
        </Box>
      </Label>
      {error && <ErrorMessage error={error} />}
    </Flex>
  )
}
