/** @jsx jsx **/
import { jsx, Box, Flex, Text, Input } from 'theme-ui'
import { FC, useState } from 'react'
import theme from '~/gatsby-plugin-theme-ui'
import { Button } from '~/components/button'
import { FieldHelperProps } from 'formik'

type Props = {
  setValue: FieldHelperProps<string>['setValue']
}

const FileUpload: FC<Props> = ({ setValue, ...rest }) => {
  const { label, name } = rest
  const [file, setFile] = useState('')
  const [dragging, setDragging] = useState(false)

  return (
    <Box>
      <Text mb={theme.space.sm1}>{label}</Text>
      <Flex
        sx={{
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          px: theme.space.gridGap3,
          bg: 'bg',
          borderWidth: '1px',
          borderStyle: dragging ? 'dashed' : 'solid',
          borderColor: dragging ? 'text' : 'strokes',
          height: 172,
          transition: 'all .3s',
        }}
      >
        <Input
          id={name}
          name={name}
          onChange={e => {
            if (e.currentTarget.files) {
              setValue(e.currentTarget.files[0])
            }
            setFile(e.currentTarget.files[0])
          }}
          multiple={false}
          onBlur={e => {
            if (e.currentTarget.files) {
              setValue(e.currentTarget.files[0])
            }
          }}
          onDragOver={() => setDragging(true)}
          onDragLeave={() => setDragging(false)}
          onDragEnd={() => setDragging(false)}
          onDrop={() => setDragging(false)}
          type="file"
          sx={{
            position: 'absolute',
            opacity: 0,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        />

        <Text
          variant="micro"
          as={Box}
          sx={{
            textAlign: 'center',
            mb: theme.space.md1,
          }}
        >
          {file ? (
            <span>{file.name}</span>
          ) : (
            <span>
              Drag and drop file <br /> or
            </span>
          )}
        </Text>
        {file ? (
          <Button
            type="button"
            label="Remove File"
            onClick={() => {
              setValue('')
              setFile('')
            }}
            sxProps={{
              minWidth: ['initial'],
              display: 'block',
              px: [theme.space.md1],
              height: 48,
              fontSize: '16px',
              bg: 'transparent',
              color: 'text',
              textDecoration: 'underline',
              border: 'none',
              position: 'relative',
            }}
          />
        ) : (
          <Button
            type="button"
            label="Browse Computer"
            tabIndex={-1}
            sxProps={{
              minWidth: ['initial'],
              display: 'block',
              px: [theme.space.md1],
              height: 48,
              fontSize: '16px',
            }}
          />
        )}
      </Flex>
    </Box>
  )
}

export default FileUpload
