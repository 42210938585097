/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC } from 'react'
import { Form as FormikForm, Formik } from 'formik'
import ErrorFocus from '~/components/error-focus'
import ErrorMessage from '~/components/error-message'
import { Button } from '~/components/button'
import { Maybe } from '~/@types/models'

type Props = {
  formProps: object
  buttonLabel: string
  isLoading?: boolean
  serverError?: Maybe<string>
}

const Form: FC<Props> = ({
  children,
  formProps,
  serverError = null,
  isLoading = false,
  buttonLabel,
}) => {
  return (
    <Formik {...formProps}>
      <FormikForm>
        <ErrorFocus />
        {children}
        <Button
          label={isLoading ? '...' : buttonLabel}
          disabled={isLoading}
          sxProps={{
            width: '100%',
            mt: [82],
          }}
          type="submit"
          hasArrow={false}
        />
        {serverError && <ErrorMessage error={serverError} data-cy="server-error" />}
      </FormikForm>
    </Formik>
  )
}

export default Form
