/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { FC, InputHTMLAttributes } from 'react'
import { useField } from 'formik'
import { Input } from '~/components/input'
import { ReadField } from '~/components/read-field'
import { Checkbox } from '~/components/checkbox'
import { Select } from '~/components/select'
import RadioGroup from '~/components/radio-group'
import FileUpload from '~/components/file-upload'
import { space } from '~/gatsby-plugin-theme-ui'
import { DateInput } from '../date-input'
import { PasswordField } from '../password-input'

type radioGroupType = {
  id: string
  label: string
}

type Props = InputHTMLAttributes<HTMLInputElement> & {
  as?:
    | 'input'
    | 'checkbox'
    | 'select'
    | 'radioGroup'
    | 'file'
    | 'input-read'
    | 'date'
    | 'input-password'
  options?: string[]
  name: string
  label: string
  radioGroup?: {
    options: radioGroupType[]
  }
  selectDefaultOption?: string
  selectDefaultOptionDisabled?: boolean
  labelHidden?: boolean
  labelUppercase?: boolean
  wrapperNoSpacing?: boolean
  rightIcon?: JSX.Element
  selectAsImput?: boolean
}

const FormField: FC<Props> = ({
  as = 'input',
  options = [],
  radioGroup,
  selectDefaultOptionDisabled = true,
  selectDefaultOption = 'Select Option',
  labelHidden = false,
  labelUppercase = false,
  wrapperNoSpacing = false,
  rightIcon,
  selectAsImput = false,
  ...rest
}) => {
  // console.log(rest);
  const [field, meta, { setValue }] = useField(rest)
  const hasError = meta.touched && meta.error

  if (as === 'radioGroup') {
    return (
      <RadioGroup {...field} {...rest} error={hasError} options={radioGroup?.options} />
    ) as JSX.Element
  }

  if (as === 'select') {
    return (
      <Box
        sx={{
          mb: [space.inputGap],
        }}
      >
        <Select
          {...field}
          {...rest}
          sxLabel={{ color: '#595858d9' }}
          error={hasError}
          options={options}
          setValue={setValue}
          selectDefaultOption={selectDefaultOption}
          selectDefaultOptionDisabled={selectDefaultOptionDisabled}
          labelUppercase={labelUppercase}
          selectAsImput={selectAsImput}
        />
      </Box>
    ) as JSX.Element
  }

  if (as === 'input') {
    return (
      <Box
        sx={{
          mb: wrapperNoSpacing ? 0 : [space.inputGap],
        }}
      >
        <Input
          {...field}
          {...rest}
          error={hasError}
          setValue={setValue}
          labelHidden={labelHidden}
          rightIcon={rightIcon}
        />
      </Box>
    ) as JSX.Element
  }

  if (as === 'input-read') {
    return (
      <Box
        sx={{
          mb: wrapperNoSpacing ? 0 : [space.inputGap],
        }}
      >
        <ReadField
          {...field}
          {...rest}
          error={hasError}
          setValue={setValue}
          labelHidden={labelHidden}
        />
      </Box>
    ) as JSX.Element
  }

  if (as === 'file') {
    return (
      <Box
        sx={{
          mb: [space.inputGap],
        }}
      >
        <FileUpload {...field} {...rest} error={hasError} setValue={setValue} />
      </Box>
    ) as JSX.Element
  }

  return (<Checkbox {...field} {...rest} error={hasError} />) as JSX.Element
}

export default FormField
