/** @jsx jsx **/
import { jsx, Flex, Label, Input as StyledInput } from 'theme-ui'
import { FC, InputHTMLAttributes } from 'react'
import { Maybe } from '~/@types/models'
import ErrorMessage from '~/components/error-message'
import { WrapperInput } from './stylest'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  label?: string
  error?: Maybe<string>
  setValue: any
  labelHidden?: boolean
  rightIcon?: JSX.Element
  labelUppercase?: JSX.Element
}

export const Input: FC<Props> = ({
  label,
  name,
  error = null,
  labelHidden,
  rightIcon,
  labelUppercase,
  ...rest
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Label
        htmlFor={name}
        // @ts-ignore
        sx={{
          ...(labelHidden && {
            variant: 'variants.visuallyHidden',
          }),
          fontSize: '16px',
          color: '#595858',
          ...(labelUppercase && {
            textTransform: 'uppercase',
          }),
        }}
        style={{
          fontSize: '16px',
        }}
      >
        {label}
      </Label>
      <WrapperInput>
        <StyledInput id={name} name={name} {...rest} variant={error ? 'inputError' : 'input'} />
        {rightIcon}
      </WrapperInput>
      {error && <ErrorMessage error={error} data-cy={`error-${name}`} />}
    </Flex>
  ) as JSX.Element
}
