/** @jsx jsx **/
import { jsx, Flex, Label, Field, Input } from 'theme-ui'
import { FC, InputHTMLAttributes } from 'react'
import { Maybe } from '~/@types/models'
import ErrorMessage from '~/components/error-message'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  label?: string
  error?: Maybe<string>
  setValue: any
  labelHidden?: boolean
}

export const ReadField: FC<Props> = ({ label, name, error = null, labelHidden, ...rest }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      <Label
        htmlFor={name}
        // @ts-ignore
        sx={{
          ...(labelHidden && {
            variant: 'variants.visuallyHidden',
          }),
          fontSize: '16px',
          color: '#595858',
          textTransform: 'uppercase',
        }}
        style={{
          fontSize: '16px',
        }}
      >
        {label}
      </Label>
      <Input
        sx={{
          border: 'none',
          background: 'none',
          padding: '2px',
        }}
        id={name}
        name={name}
        {...rest}
        variant={error ? 'inputError' : 'input'}
      />
      {/* <Field
        sx={{
          border: 'none',
        }}
        id={name}
        name={name}
        {...rest}
      /> */}
    </Flex>
  ) as JSX.Element
}
