/** @jsx jsx */
import { jsx } from 'theme-ui'
import { FC } from 'react'
import { navigate } from 'gatsby-link'
import Form from '~/components/form'
import useFormSubmission from '~/hooks/use-form-submission'
import { object, string } from 'yup'
import FormField from '~/components/form-field'
import urls from '~/common/urls'
import { useCustomerContext } from '~/context/customer-context'

type FormValues = {
  email: string
}

const schema = object().shape({
  email: string().email().required(),
})

type Props = {}

const ForgotPassword: FC<Props> = () => {
  const { resetPassword } = useCustomerContext()

  const submitCallback = async (values: FormValues) => {
    await resetPassword(values.email)
    navigate(`${urls.account.signIn}?state=password-recovered`)
  }

  const { onSubmit, isLoading, serverError } = useFormSubmission<FormValues, any>(submitCallback)

  return (
    <Form
      formProps={{
        onSubmit,
        validationSchema: schema,
        initialValues: {},
      }}
      isLoading={isLoading}
      serverError={serverError}
      buttonLabel="Change password"
      data-cy="forgot-password-form"
    >
      <FormField type="text" name="email" label="Email address" data-cy="email" />
    </Form>
  )
}

export default ForgotPassword
